.logo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.logo a {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-decoration: none;
  color: rgb(40, 40, 40);
  font-size: 1.6em;
  padding-bottom: 0.2em;
}

.logo a:hover {
  color: rgb(40, 40, 40);
}
